import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  Edit,
  Show,
  SimpleShowLayout
} from "react-admin";

export const LabelList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <EditButton basePath="/labels" />
      <ShowButton basePath="/labels" />
    </Datagrid>
  </List>
);

export const LabelCreate = (props: any) => (
  <Create title="Create a label" {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const LabelEdit = (props: any) => (
  <Edit title="Edit label" {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const LabelShow = (props: any) => (
  <Show title="Label details" {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);
