import { Admin, Resource } from "react-admin";
import LabelIcon from '@material-ui/icons/Label';
import ClassIcon from '@material-ui/icons/Class';
import { LabelCreate, LabelList, LabelEdit, LabelShow } from "./Label";
import { TypeCreate, TypeList, TypeEdit, TypeShow } from "./Type";
import { ItemCreate, ItemList, ItemEdit, ItemShow } from "./Item";
import { PatTestCreate, PatTestEdit, PatTestList, PatTestShow } from "./PatTest";
import CustomLoginPage from './CustomLoginPage';

import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  RAFirebaseOptions,
} from 'react-admin-firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCr6NdYqbXMpgHRsihjw34UpSrzzgzToJ8",
  authDomain: "inventory-244221.firebaseapp.com",
  databaseURL: "https://inventory-244221.firebaseio.com",
  projectId: "inventory-244221",
  storageBucket: "inventory-244221.appspot.com",
  messagingSenderId: "750468549606",
  appId: "1:750468549606:web:41aa8edd5c97a4aa"
};

const options: RAFirebaseOptions = {
  logging: true,
  persistence: 'local',
  dontAddIdFieldToDoc: false
};

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);

export const App = () => (
  <Admin
    loginPage={CustomLoginPage}
    dataProvider={dataProvider}
    authProvider={authProvider}>
    <Resource name="types" list={TypeList} create={TypeCreate} edit={TypeEdit} show={TypeShow} icon={ClassIcon} />
    <Resource name="labels" list={LabelList} create={LabelCreate} edit={LabelEdit} show={LabelShow} icon={LabelIcon} />
    <Resource name="items" list={ItemList} create={ItemCreate} edit={ItemEdit} show={ItemShow} />
    <Resource name="pattests" options={{ label: 'PAT Tests' }}  list={PatTestList} create={PatTestCreate} edit={PatTestEdit} show={PatTestShow} />
  </Admin>
);
