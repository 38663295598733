import {
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  Edit,
  Show,
  SimpleShowLayout,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter
} from "react-admin";

const PostFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const TypeList = (props: any) => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="code" />
      <TextField source="name" />
      <EditButton basePath="/types" />
      <ShowButton basePath="/types" />
    </Datagrid>
  </List>
);

export const TypeCreate = (props: any) => (
  <Create title="Create a type" {...props}>
    <SimpleForm>
      <TextInput source="code" />
      <TextInput source="name" />
      <ReferenceArrayInput label="Label" source="labels" reference="labels" allowempty>
        <AutocompleteArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export const TypeEdit = (props: any) => (
  <Edit title="Edit a type" {...props}>
    <SimpleForm>
      <TextInput source="code" />
      <TextInput source="name" />
      <ReferenceArrayInput label="Label" source="labels" reference="labels" allowempty>
        <AutocompleteArrayInput optionText="name" optionValue="id" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const TypeShow = (props: any) => (
  <Show title="Type details" {...props}>
    <SimpleShowLayout>
      <TextField source="code" />
      <TextField source="name" />
      <ReferenceArrayField label="Label" source="labels" reference="labels">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
