import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

export const ItemList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="assetId" />
      <ReferenceField label="Type" source="typeId" reference="types">
        <TextField source="name" />
      </ReferenceField>
      <EditButton basePath="/items" />
      <ShowButton basePath="/items" />
    </Datagrid>
  </List>
);

export const ItemCreate = (props: any) => (
  <Create title="Create an item" {...props}>
    <SimpleForm>
      <TextInput source="assetId" />
      <ReferenceInput label="Type" source="typeId" reference="types">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput label="Purchase Cost" source="purchaseCost" step="0.01" />
      <DateInput label="Purchase Date" source="purchaseDate" />
      <NumberInput label="Replacement Cost" source="replacementCost" step="0.01" />
      <NumberInput label="New Cost" source="newCost" step="0.01" />
      <BooleanInput label="Requires PAT testing" source="requiresPat" />
    </SimpleForm>
  </Create>
);

export const ItemEdit = (props: any) => (
  <Edit title="Edit an item" {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="assetId" />
      <ReferenceInput label="Type" source="typeId" reference="types">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput label="Purchase Cost" source="purchaseCost" step="0.01" />
      <DateInput label="Purchase Date" source="purchaseDate" />
      <NumberInput label="Replacement Cost" source="replacementCost" step="0.01" />
      <NumberInput label="New Cost" source="newCost" step="0.01" />
      <BooleanInput label="Requires PAT testing" source="requiresPat" />
    </SimpleForm>
  </Edit>
);

export const ItemShow = (props: any) => (
  <Show title="Item details" {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <TextField label="Asset Id" source="assetId" />
      <ReferenceField label="Type" source="typeId" reference="types">
        <TextField source="name" />
      </ReferenceField>
      <NumberField label="Purchase Cost" source="purchaseCost" />
      <DateField label="Purchase Date" source="purchaseDate" />
      <NumberField label="Replacement Cost" source="replacementCost" />
      <NumberField label="New Cost" source="newCost" />
      <BooleanField label="Requires PAT testing" source="requiresPat" />
    </SimpleShowLayout>
  </Show>
);
