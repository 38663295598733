import { SelectField } from "react-admin";
import { RadioButtonGroupInput } from "react-admin";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

export const PatTestList = (props: any) => (
  <List title="PAT Test Results" {...props}>
    <Datagrid>
      <ReferenceField label="Item" source="assetId" reference="items">
        <TextField source="assetId" />
      </ReferenceField>
      <DateField label="Test date" source="testDate" />
      <BooleanField label="Passed" source="pass" />
      <EditButton basePath="/pattest" />
      <ShowButton basePath="/pattest" />
    </Datagrid>
  </List>
);

export const PatTestCreate = (props: any) => (
  <Create title="Add a PAT result" {...props}>
    <SimpleForm>
      <ReferenceInput label="Item" source="assetId" reference="items" filter={{ requiresPat: true }}>
        <SelectInput optionText="assetId" />
      </ReferenceInput>
      <DateInput label="Test date" source="testDate" />
      <RadioButtonGroupInput source="class" choices={[
        { id: 'class_1', name: 'Class 1' },
        { id: 'class_2', name: 'Class 2' },
      ]} />
      <BooleanInput label="Visual inspection passed" source="visualInspection" />
      <NumberInput label="Earth Test (Ohms)" source="earthTest" />
      <NumberInput label="Insulation Test (MOhms)" source="insulationTest" />
      <BooleanInput label="Functional check" source="functionalCheck" />
      <BooleanInput label="Pass" source="pass" />
      <DateInput label="Next test due" source="nextTestDate" />
      <TextInput label="Test notes" source="notes" />
    </SimpleForm>
  </Create>
);

export const PatTestEdit = (props: any) => (
  <Edit title="Edit PAT Test" {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <ReferenceInput label="Item" source="assetId" reference="items" filter={{ requiresPat: true }}>
        <SelectInput optionText="assetId" />
      </ReferenceInput>
      <DateInput label="Test date" source="testDate" />
      <RadioButtonGroupInput source="class" choices={[
        { id: 'class_1', name: 'Class 1' },
        { id: 'class_2', name: 'Class 2' },
      ]} />
      <BooleanInput label="Visual inspection passed" source="visualInspection" />
      <NumberInput label="Earth Test (Ohms)" source="earthTest" />
      <NumberInput label="Insulation Test (MOhms)" source="insulationTest" />
      <BooleanInput label="Functional check" source="functionalCheck" />
      <BooleanInput label="Pass" source="pass" />
      <DateInput label="Next test due" source="nextTestDate" />
      <TextInput label="Test notes" source="notes" />
    </SimpleForm>
  </Edit>
);

export const PatTestShow = (props: any) => (
  <Show title="PAT Test Details" {...props}>
    <SimpleShowLayout>
      <TextField disabled label="Id" source="id" />
      <ReferenceField label="Item" source="assetId" reference="items" filter={{ requiresPat: true }}>
        <TextField source="assetId" />
      </ReferenceField>
      <DateField label="Test date" source="testDate" />
      <SelectField source="class" choices={[
        { id: 'class_1', name: 'Class 1' },
        { id: 'class_2', name: 'Class 2' },
      ]} />
      <BooleanField label="Visual inspection passed" source="visualInspection" />
      <NumberField label="Earth Test (Ohms)" source="earthTest" />
      <NumberField label="Insulation Test (MOhms)" source="insulationTest" />
      <BooleanField label="Functional check" source="functionalCheck" />
      <BooleanField label="Pass" source="pass" />
      <DateField label="Next test due" source="nextTestDate" />
      <TextField label="Test notes" source="notes" />
    </SimpleShowLayout>
  </Show>
);
